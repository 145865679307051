

const windowProxy2 = new Porthole.WindowProxy('todo1');
function sendMessage(message) {
    windowProxy2.post(message);
}
function resolveHeight() {
    const currentHeight = getDocumentHeight();
    if (!sessionStorage.getItem("documentHeight")) {
        sessionStorage.setItem("documentHeight", -1);
    }
    const lastHeight = parseInt(sessionStorage.getItem("documentHeight"), 10);
    if (currentHeight !== lastHeight) {
        sessionStorage.setItem("documentHeight", currentHeight);
        sendMessage({ 'action': 'resizeIframe', 'param': [{ 'height': currentHeight + 'px' }] });
    }
}
function getDocumentHeight() {
    var height, scrollHeight, offsetHeight;
    if (document.height) {
        height = document.getElementById('content').height;
    } else if (document.getElementById('content')) {
        if (document.getElementById('content').scrollHeight) {
            height = scrollHeight = document.getElementById('content').scrollHeight;
        }
        if (document.getElementById('content').offsetHeight) {
            height = offsetHeight = document.getElementById('content').offsetHeight;
        }
        if (scrollHeight && offsetHeight) {
            height = Math.max(scrollHeight, offsetHeight);
        }
    }
    return height;
}
window.onload = function (event) {
    sessionStorage.removeItem("documentHeight");
    window.setInterval(resolveHeight, 500);
}
 
windowProxy2.addEventListener(
    function (event) {
        if (event.data['action'] === 'closeSession') {
            const http = new XMLHttpRequest();
            http.open('POST', sessionStorage.getItem('urlClose'));
            http.setRequestHeader('Content-type', 'application/json');
            http.send(sessionStorage.getItem('params'));
            http.onload = function () {
              }  
        }
    });